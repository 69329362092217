/* Custom */

.App {
  text-align: center;
  padding: 20px 0px;
}

.projectContainer {
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 64px;
}

.headerPrimary {
  background-color: #284059 !important;
}

.NavTab {
  margin: 10px 0px;
}

.appTitle {
  padding-top: 1%;
  padding-bottom: 1%;
  text-align: center;
}

.footerFlexItem {
  margin-left: 0.3em;
}

.footerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary {
  color: #fcb24b;
}

.whitespace {
  margin: 20px 0px;
}

.center-crop {
  width: 200px;
  height: 200px;
  object-position: center;
  object-fit: cover;
  border: 5px solid #345928;
  border-radius: 1000px;
}

.under::after {
  content: "";
  display: block;
  height: 4px;
  width: 70px;
  background-color: #fcb24b;
  margin-top: 10px;
}

.tabLink:hover {
  background-color: #fcb23b;
  transition: background-color 0.7s;
  color: #000000;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
  opacity: 0.85;
}

.cardBody:hover .MuiCardMedia-img {
  transform: scale(1.15);
  transition-timing-function: ease-in-out;
  transition: all 1s;
}

.cardBody:focus .MuiCardMedia-img {
  transform: scale(1.15);
  transition-timing-function: ease-in-out;
  transition: all 1s;
}

.cardBody .MuiTypography-h5::after {
  content: "";
  display: block;
  height: 4px;
  width: 80px;
  background: linear-gradient(to right, #fcb23b 50%, #ffffff 50%);
  background-position: right bottom;
  background-size: 200% 100%;
  margin: 1px 0px;
  transition: all 0.3s;
}

.cardBody:hover .MuiTypography-h5::after {
  background-position: left bottom;
}

.cardBody:hover .MuiCardContent-root {
  background-color: #ffffff;
  transition: all 0.5s;
}

.centerButton {
  text-align: center;
  margin-top: 1em;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
  background-color: #ffffff;
  color: #345928;
  border: 2px solid #345928;
  border-radius: 2px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary:hover {
  background-color: #fcb23b;
  color: #000000;
  border: 2px solid #fcb23b;
  transition: all 0.3s;
}

.centerText {
  text-align: center;
}

.iconPrimary {
  color: #1f7341;
  width: 2em !important;
  height: 2em !important;
  border: 5px solid #1f7341;
  border-radius: 2000px;
  padding: 20px;
}

.contactHover:hover {
  transform: scale(1.15);
  transition-timing-function: ease-in-out;
  transition: all 1s;
}

.contactHover svg:hover {
  color: #fcb24b;
  width: 2em !important;
  height: 2em !important;
  border: 5px solid #fcb24b;
  border-radius: 2000px;
  padding: 20px;
  transform: scale(1.15);
  transition-timing-function: ease-in-out;
  transition: transform 0.7s;
}

/* Desktop only styles */

@media only screen and (min-width:768px) {
  .gridRoot {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    padding: 20px 0px;
    flex-wrap: wrap;
  }

  .MuiCard-root a {
    overflow: hidden;
    height: 360px;
    padding-bottom: 1em;
  }

  .gridItem {
    flex-grow: 1;
    max-width: 31%;
    margin-top: 2em;
  }
}

/* Mobile only styles */

@media only screen and (max-width:767px) {
  .App {
    padding: 10px 0px;
  }

  .gridItem {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px
  }
}
